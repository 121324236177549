import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "checkAllCheckbox", "removeCarsForm"];

  toggleAllCheckboxes(event) {
    const element = event.target;
    const isChecked = element.checked;

    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = isChecked;

      const value = checkbox.value;

      if (isChecked) {
        this.#addValueToRemoveCarsForm(value);
      } else {
        this.#removeValueFromRemoveCarsForm(value);
      }
    });
  }

  toggleCheckbox(event) {
    const element = event.target;
    const isChecked = element.checked;

    const value = element.value;

    if (isChecked) {
      this.checkAllCheckboxTarget.checked = this.checkboxTargets.every(
        (checkbox) => checkbox.checked
      );

      this.#addValueToRemoveCarsForm(value);
    } else {
      this.checkAllCheckboxTarget.checked = false;
      this.#removeValueFromRemoveCarsForm(value);
    }
  }

  #addValueToRemoveCarsForm(value) {
    const input = document.createElement("input");

    input.type = "hidden";
    input.name = "fleet_plan_membership_ids[]";
    input.value = value;

    this.removeCarsFormTarget.appendChild(input);
  }

  #removeValueFromRemoveCarsForm(value) {
    const input = this.removeCarsFormTarget.querySelector(`input[value="${value}"]`);
    this.removeCarsFormTarget.removeChild(input);
  }
}
